import React, { Component } from 'react';
import { connect, Provider } from 'react-redux'
import './App.css';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
//container
import CourseIntro from './container/CourseIntro'
import ChapterSelect from './container/ChapterSelect'
import Homepage from './container/Homepage';
import Login from './container/login';
import LoginVerify from './container/loginVerify';
import LoginIframe from './container/logins';
import ResourcesLogin from './container/ResourcesLogin';
import Registered from './container/Registered';
import Authority from './container/authority';
import Examine from './container/examine';
import Profile from './container/profile';
import Admin from './container/admin';
import CourseUpload from './container/CourseUpload'
import CourseRecordUpload from './container/CourseRecordUpload'
import CourseClassify from './container/CourseClassify'
import CourseClassify2 from './container/CourseClassify2'
import CourseManage from './container/CourseManage'
import RoomCreate from './container/RoomCreate'
import UserInfo from './container/UserInfo'
import NewCourseUpload from './container/NewCourseUpload'
import MobileHome from './container/mobileHome'
import MobileView from './container/mobileView'
import MobileSub from './container/mobileSub'
import RoomResult from './components/RoomResult'
import ThirdLogin from './container/thirdLogin'
//components
import Nav from './components/Nav'
import Footer from './components/Footer'
//css
import styled from 'styled-components'
//component
import SuggestResolution from './components/SuggestResolution';
// store
import store from './redux/Store';
//config
import config from './config';
import axios from "axios";
import qs from 'qs';
// 忘记密码
import PassWord from './container/passWord'
// 创建房间
import Room from './container/room'

import dataLang from './data.json'

import { getLogin } from "./http/api"
import { Modal, Fade } from '@material-ui/core';

const modalStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const Wrapper = styled.div`
  overflow-y:auto;
  overflow-x:hidden;
  // position:relative;
  display:grid;
  grid-template-columns: repeat(1, calc(100% / 1));
  grid-template-rows: 67.5px 1fr 67.5px;
  min-height: 100vh;
  grid-template-areas:
  'nav'
  'body'
  'footer';
  font-size:12px;
  @media screen and (max-width:1024px){
    font-size:10px;
  }
`;
// cookie
const reg = new RegExp("(^| )" + 'cocorobo' + "=([^;]*)(;|$)");
let arr = document.cookie.match(reg) && document.cookie.match(reg)[2];
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: true,
      language: '',
      isData: true,
      count: 0,
      open: false,
      userId: "",
    }
  }
  componentDidMount() {
    // window.localStorage['identity'] = undefined
    if (window.localStorage.language && JSON.parse(window.localStorage.language) === 1) {
      this.setState({
        language: dataLang.language.english
      })
    } else {
      window.localStorage['language'] = 2
      this.setState({
        language: dataLang.language.chinese
      })
    }
    // console.log(window.parent.location)
    // cloud 调用登录，导航栏信息不显示

    try {
      if (window.parent.location.hostname.indexOf('edu.cocorobo') === -1) {
        document.getElementById('FOOTER').style.display = 'none';
        document.getElementById('NAV').style.display = 'none';
        if (document.getElementById('thirdPartyLogin')) {
          document.getElementById('thirdPartyLogin').style.display = 'none';
        }
        if (document.getElementById('thirdPartyRegistered')) {
          document.getElementById('thirdPartyRegistered').style.display = 'none';
        }
        if (document.getElementById('forgetPassword')) {
          document.getElementById('forgetPassword').style.display = 'none';
        }
      }
    } catch (error) {
      // if (error.indexOf("from accessing a cross-origin frame") != -1) {
      document.getElementById('FOOTER').style.display = 'none';
      document.getElementById('NAV').style.display = 'none';
      if (document.getElementById('thirdPartyLogin')) {
        document.getElementById('thirdPartyLogin').style.display = 'none';
      }
      if (document.getElementById('thirdPartyRegistered')) {
        document.getElementById('thirdPartyRegistered').style.display = 'none';
      }
      if (document.getElementById('forgetPassword')) {
        document.getElementById('forgetPassword').style.display = 'none';
      }
      // }
      console.log(error)
    }
    setTimeout(() => {
      if (!!!arr) {
      } else {
        axios.get(`${config.env.server}getcookieuserid`).then(res => {
          var userId = res.data[0][0].userid
          // var userId = 'a77e9404-efec-11e9-96f9-028edca3b798'
          this.setState({
            userId: userId
          })
          axios.get(config.env.edu + "admin/userinfo/userinfoById/" + userId + "/").then(r => {
            var data = r.data[0][0];
            console.log(data.alias);
            if (this.isNull(data.alias) || data.alias == null) {
              setTimeout(() => {
                this.setState({
                  open: true
                })
              }, 0);
            }
          });
        })
      }
    }, 3000);
  }

  updateName = () => {
    const { userId } = this.state
    let username = document.getElementsByClassName("app_uname")[0]
    if (this.isNull(username.value)) {
      window.alert("姓名设置不能为空");
      return;
    }
    axios.get(`${config.env.edu}updateUserName/${username.value}/${userId}`).then(r => {
      this.setState({
        open: false
      })
    });
  }

  isNull = str => {
    if (str == "") {
      return true
    };
    var regu = "^[ ]+$";
    var re = new RegExp(regu);
    return re.test(str);

  }

  getLanguage = (v) => {
    if (v === "English") {
      this.setState({
        language: dataLang.language.english
      })
    } else {
      this.setState({
        language: dataLang.language.chinese
      })
    }
  }

  render() {
    const { language, isData, open } = this.state
    // if (!isData) {

    //   // return <Redirect to={{ pathname: "/" }} />;

    //   return <Redirect to='/thirdlogin' />;
    // }
    return (
      <Provider store={store}>
        <Router basename='/course' >
          {/*<ScrollToTop >*/}
          <div className="App">
            <Wrapper>
              <Route path='/' render={({ match, location, history }) => (
                <Nav language={language} areaname='nav' match={match} location={location} history={history} getLanguage={v => this.getLanguage(v)} />
              )} />
              <Switch>
                <Route path='/' exact render={({ match, location }) => (
                  <Homepage areaname='body' match={match} location={location} language={language} />
                )} />
                <Route path='/401' exact render={({ match, location, history }) => (
                  <Authority areaname='authority' match={match} location={location} history={history} language={language} />
                )} />
                <Route path='/examine/:courseId/:userId/' exact render={({ match, location, history }) => (
                  <Examine areaname='examine' match={match} location={location} history={history} language={language} />
                )} />
                <Route path='/login' exact render={({ match, location, history }) => (
                  <Login areaname='login' match={match} location={location} history={history} language={language} />
                )} />
                <Route path='/userVerify' exact render={({ match, location, history }) => (
                  <LoginVerify areaname='login' match={match} location={location} history={history} language={language} />
                )} />
                <Route path='/LoginIframe' exact render={({ match, location, history }) => (
                  <LoginIframe areaname='LoginIframe' match={match} location={location} history={history} language={language} />
                )} />
                <Route path='/ResourcesLogin' exact render={({ match, location, history }) => (
                  <ResourcesLogin areaname='ResourcesLogin' match={match} location={location} history={history} language={language} />
                )} />
                <Route path='/registered' exact render={({ match, location, history }) => (
                  <Registered areaname='registered' match={match} location={location} history={history} language={language} />
                )} />
                <Route path='/thirdlogin' exact render={({ match, location }) => (
                  <ThirdLogin areaname='thirdlogin' match={match} location={location} language={language} />
                )} />
                <Route path='/password' exact render={({ match, location, history }) => (
                  <PassWord areaname='password' match={match} location={location} history={history} language={language} />
                )} />
                <Route path='/room' exact render={({ match, location, history }) => (
                  <Room areaname='room' match={match} location={location} history={history} language={language} />
                )} />
                <Route path='/profile' exact render={({ match, location, history }) => (
                  <Profile areaname='profile' match={match} location={location} history={history} language={language} />
                )} />
                <Route path='/admin' exact render={({ match, location, history }) => (
                  <Admin areaname='admin' match={match} location={location} history={history} language={language} />
                )} />
                <Route
                  path={'/upload'}
                  render={({ match, history }) => (<CourseUpload areaname='body' match={match} history={history} language={language} />)}
                />
                <Route
                  path={'/recordUpload'}
                  render={({ match, history }) => (<CourseRecordUpload areaname='body' match={match} history={history} language={language} />)}
                />
                <Route
                  path={'/courseClassify'}
                  render={({ match, history }) => (<CourseClassify areaname='body' match={match} history={history} language={language} />)}
                />
                <Route
                  path={'/courseClassify2'}
                  render={({ match, history }) => (<CourseClassify2 areaname='body' match={match} history={history} language={language} />)}
                />
                <Route
                  path={'/CourseManage'}
                  render={({ match, history }) => (<CourseManage areaname='body' match={match} history={history} language={language} />)}
                />
                <Route
                  path={'/roomResult'}
                  render={({ match, history }) => (<RoomResult areaname='body' match={match} history={history} language={language} />)}
                />

                {/* <Route
                  path={'/upload'}
                  render={({ match, history }) => (<CourseUpload areaname='body' match={match} history={history} />)}
                /> */}
                {/* <Route path='/mobileHome' exact render={({ match, location, history }) => (
                  <MobileHome areaname='admin' match={match} location={location} history={history} language={language} />
                )} />
                <Route path='/mobileView' exact render={({ match, location, history }) => (
                  <MobileView areaname='admin' match={match} location={location} history={history} language={language} />
                )} />
                <Route path='/mobileSub' exact render={({ match, location, history }) => (
                  <MobileSub areaname='admin' match={match} location={location} history={history} language={language} />
                )} /> */}
                <Route
                  path={'/newupload'}
                  render={({ match }) => (<NewCourseUpload areaname='body' match={match} />)}
                />
                <Route
                  path={'/my'}
                  exact
                  render={({ match }) => (<UserInfo areaname='body' />)}
                />
                <Route
                  path={'/create'}
                  render={({ match }) => (<RoomCreate areaname='body' />)}
                />
                <Route
                  path={`${config.env.basename}/course/:courseId/`}
                  exact
                  render={({ match, location, history }) => (
                    <CourseIntro
                      areaname='body'
                      history={history}
                      match={match}
                      location={location}
                    />
                  )} />
                <Route
                  path={`${config.env.basename}/:courseId/`}
                  exact
                  render={({ match, location, history }) => (
                    <CourseIntro
                      history={history}
                      areaname='body'
                      match={match}
                      location={location}
                      language={language}
                    />
                  )} />
                <Route
                  path={`${config.env.basename}/:courseId/chapter`}
                  exact render={({ match, location, history }) => (
                    <ChapterSelect
                      history={history}
                      areaname='body'
                      match={match}
                      language={language}
                      location={location}
                    />
                  )} />
                <Route
                  path={`/course/:courseId/`}
                  exact
                  render={({ match }) => (<Redirect to={`${config.env.basename}/${match.params.courseId}`} />)}
                />
                <Route render={() => (<Redirect to='/course/' />)}></Route>
              </Switch>
              <Footer areaname='footer' />
              {!isData && <Route render={() => (<Redirect to='/thirdlogin' />)}></Route>}
              {/*  */}
              {/*<SuggestResolution/>*/}
            </Wrapper>
          </div>
          {/* <Wrapper></Wrapper> */}
          {/*</ScrollToTop>*/}
        </Router>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={modalStyle}
          open={open}
          onClose={() => {
            this.setState({
              open: true
            })
          }}
        >
          <Fade in={open}>
            <div className="app_checkModal">
              <div className="title">
                <h2>设置昵称</h2>
              </div>
              <div style={{ height: "calc(100% - 140px)" }}>
                <span className="app_setNameRemarks">您还未设置姓名哦，请输入真实姓名！</span>
                <div className="app_setNameContent"><span>昵称设置：</span><input type="text" class="app_uname" /></div>
              </div>
              <div className="submit">
                <button style={{ background: 'rgb(242,58,58)' }} onClick={() => { this.updateName() }}>确定</button>
              </div>
            </div>
          </Fade>
        </Modal>
      </Provider>
    );
  }
}

export default App;
